<template>
  <div class="CmsTop">
    <div id="applicantWrap" class="mt-5 tableWrap">
      <v-form-select
        itemStyle="line"
        label="申請"
        suffix="年度"
        labelGridClass="max-w80"
        inputClass="max-w120"
        labelSize="title"
        size="sm"
        :options="yearOptions.app"
        :hasDefault="false"
        v-model="selectedYears.app"
        @change="yearChange('app')"
      >
      </v-form-select>
      <b-table
        show-empty
        :border="false"
        :fields="heads.app"
        :items="showData.app"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        sort-by="name"
        :sort-desc="false"
        id="applicantTable"
      >
        <template v-slot:cell(editButton)="data">
          <b-link
            :to="'/cms/applications/list/'+selectedYears.app+'/'+data.item.typeId">
            申請管理
          </b-link>
        </template>
        <template v-slot:empty="scope">
          <p class="none-data">登録されている申請種別がありません。</p>
        </template>
      </b-table>
    </div>
    <div id="reportWrap" class="mt-5 tableWrap" v-if="showData.report.length > 0">
      <v-form-select
        itemStyle="line"
        label="報告"
        suffix="年度"
        labelGridClass="max-w80"
        inputClass="max-w120"
        labelSize="title"
        size="sm"
        :options="yearOptions.report"
        :hasDefault="false"
        v-model="selectedYears.report"
        @change="yearChange('report')"
      >
      </v-form-select>
      <b-table
        show-empty
        :fields="heads.report"
        :items="showData.report"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        table-class="icon-white"
        sort-by="name"
        :sort-desc="false"
      >
        <template v-slot:cell(editButton)="data">
          <b-link :to="'/cms/reports/list/'+selectedYears.report+'/'+data.item.seriesId">
            報告管理
          </b-link>
        </template>
        <template v-slot:empty="scope">
          <p class="none-data">登録されている申請種別がありません。</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';

export default {
  name: 'CmsTop',
  components: {},
  data() {
    return {
      heads: {
        app: [
          {
            key: 'name',
            label: '助成種別',
            sortable: true,
            class: 'name',
          },
          {
            key: 'appDate',
            label: '申請期間',
            sortable: true,
            class: 'appDate',
          },
          {
            key: 'counts',
            label: '件数',
            sortable: true,
            class: 'evaluations',
          },
          // {
          //   key: 'resultDate',
          //   label: '結果発表日',
          //   sortable: true,
          //   class: 'resultDate',
          // },
        ],
        report: [
          {
            key: 'name',
            label: '助成種別',
            sortable: true,
            class: 'name',
          },
          {
            key: 'adoptionCount',
            label: '採択数',
            sortable: true,
            class: 'adoptionCount',
          },
          {
            key: 'reportCount',
            label: '報告数',
            sortable: true,
            class: 'reportCount',
          },
          {
            key: 'receiptCount',
            label: '報告受領数',
            sortable: true,
            class: 'receiptCount',
          },
          {
            key: 'reportDate',
            label: '報告期間',
            sortable: false,
            class: 'reportDate',
          },
        ],
      },
      showData: {
        app: [],
        report: [],
      },
      initData: {
        app: [],
        report: [],
      },
      yearOptions: {
        app: [],
        report: [],
      },
      selectedYears: {
        app: [],
        report: [],
      },
    };
  },
  methods: {
    async initFetch(promiseFuncs) {
      const res = await api.all(promiseFuncs)
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (res !== false) {
        res.map((list) => {
          const listData = list.data;
          const listType = listData.type;
          this.initData[listType] = listData.list;
          this.yearOptions[listType] = this.setYerSelect(listData.selectYears);
          this.selectedYears[listType] = listData.selectYears.shift();
          this.showData[listType] = this.getSearchYearData(
            this.initData[listType], this.selectedYears[listType],
          );
          if (listData.hasAccessAuth) {
            this.heads[listType].push({
              key: 'editButton',
              label: '',
              sortable: false,
              class: 'link',
            });
          }
          return true;
        });
      }
    },
    /**
     * select項目用に整形
     * @param [Array] Years
     * @return [object]
     */
    setYerSelect(Years) {
      return Years.map((year) => {
        return { value: year, text: year };
      });
    },
    /**
     * 年度でリストを絞り込み
     * @param  [Object] list
     * @param  [Number] selectedYear
     * @return [Object]
     */
    getSearchYearData(list, selectedYear) {
      return list.filter((target) => {
        return (target.year === selectedYear);
      });
    },
    /**
     * 取得してきた最初の情報をコピー
     * @param  [Object]} initData
     * @return [Object]}
     */
    dataReset(initData) {
      return JSON.parse(JSON.stringify(initData));
    },
    /**
     * 年度選択時のイベント
     * @param  [String] type
     */
    yearChange(type) {
      this.showData[type] = this.dataReset(this.initData[type]);
      this.showData[type] = this.getSearchYearData(this.showData[type], this.selectedYears[type]);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading', 'CmsTop');
    const promiseFuncs = [api.send('/api/appType/list'), api.send('/api/reportType/list')];
    await this.initFetch(promiseFuncs);
    // await this.initFetch(promiseFuncs);
    this.$store.dispatch('page/offLoading', 'CmsTop');
  },
};
</script>
<style>
  .CmsTop td{
    vertical-align: baseline;
  }
  #applicantWrap .title,
  #reportWrap .title {
    color: #333;
    font-weight: normal;
  }
  #applicantWrap .max-w80,
  #reportWrap .max-w80 {
    max-width: 80px;
  }
  #applicantWrap .max-w120,
  #reportWrap .max-w120 {
    max-width: 120px;
    font-size: 18px;
  }
  #applicantWrap .max-w80,
  #reportWrap .max-w80,
  #applicantWrap .container-fluid,
  #reportWrap .container-fluid {
    margin: 0;
    padding: 0;
  }
  #applicantWrap table,
  #reportWrap table {
    border:none;
  }
  #applicantWrap .tableHead,
  #reportWrap .tableHead {
    background: #6E6E6E;
    color: #FFF;
    border:solid 1px #6E6E6E;
    height: 40px !important;
    word-break: keep-all;
  }
  #applicantWrap .tableHead th,
  #reportWrap .tableHead th {
    border:solid 1px #CCCCCC;
  }

  #applicantWrap tbody td,
  #reportWrap tbody td
  {
    vertical-align: middle;
  }
/*カラム関連*/
  td.name{
    word-break: break-all;
  }
  td.resultDate{
    width: 200px;
  }
  td.appDate,
  td.evaluations,
  td.reportDate{
    width: 380px;
  }
  td.adoptionCount,
  td.reportCount,
  td.receiptCount{
    width: 193px;
  }
  td.link{
    width: 100px;
  }
  #applicantWrap tbody tr,
  #reportWrap tbody tr
  {
    background: #FFFFFF
  }
  #reportWrap tbody tr td,
  #applicantWrap tbody tr td
  {
    border-top: 1px solid #CCCCCC;
  }
</style>
<style scoped>
  .tableWrap{
    padding: 0 5vh;
  }
  table {
    border: solid 1px #d6d6d6;
  }
  .center{
     vertical-align:super;
  }
  span {
    display: inline-block;
  }
  #applicantTable tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
  }

  #applicantTable td, #applicantTable th{
    font-size: 14px;
  }

  #applicantTable .th0{
    width: 25%;
  }
  .table th, .table td {
    vertical-align: baseline !important;
  }
  #year{
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .none-data{
    margin:0;
    text-align: center;
  }
</style>
